<script setup lang="ts">
import {useDomainDataStore} from "~/stores/domain";

const {domainData} = useDomainDataStore()

</script>

<template>
  <div class="text-white px-2 flex flex-col md:flex-row gap-4 md:gap-0">
    <div class="grow-1 basis-1/2 shrink-0 md:pr-2">
      <MoleculesFooterNewsletterForm />
    </div>
    <div class="grow-1 basis-1/2 shrink-0 md:text-right md:pl-2 flex flex-col gap-2">
      <div class="flex flex-wrap gap-4 md:flex-row-reverse">
        <NuxtLink :to="localePath('subscribe')">{{ $t('pages.pricing') }}</NuxtLink>
        <NuxtLink :to="localePath('contact')">{{ $t('pages.contact') }}</NuxtLink>
        <template v-for="(pageGroup, pageGroupIndex) in domainData.pageGroups">
          <template v-if="Array.from(pageGroup).length > 0 && pageGroupIndex === 1">
            <template v-for="pageData in pageGroup" :key="pageData.slug">
              <NuxtLink :to="localePath({name: 'page-slug', params: {slug: pageData.slug}})">{{ pageData.title }}</NuxtLink>
            </template>
          </template>
        </template>
      </div>
      <MoleculesSocialLinks color="white" class="md:justify-end" />
      <span class="opacity-50">&copy; EduMedia {{ (new Date()).getFullYear() }}</span>
    </div>
  </div>
</template>
