<script setup lang="ts">
import {useShowcaseStore} from "~/stores/showcase"
import {storeToRefs} from "pinia"
import {useDomainDataStore} from "~/stores/domain"

type Props = {
  behavior?: 'switch' | 'relocate'
}

const props = withDefaults(defineProps<Props>(), {
  behavior: 'switch'
})

const showcaseStore = useShowcaseStore()
const domainDataStore = useDomainDataStore()
const { locale } = useI18n()

const { domain } = storeToRefs(showcaseStore)

const tagName = computed(() => {
  return props.behavior === 'switch' ? 'button' : 'a'
})

function onDomainClick(dom: string) {
  if (props.behavior === 'switch') {
    domain.value = dom
  }
}

function getDomainHref(dom: string) {
  if (props.behavior === 'switch') {
    return null
  }

  return domainDataStore.getDomainHome(dom, locale.value)
}

const checkedDomain = computed(() => {
  return props.behavior === 'relocate' ? domainDataStore.domainData.domain : domain.value
})
</script>

<template>
  <div class="flex rounded-full border border-2 border-white overflow-hidden cursor-pointer">
    <component :is="tagName" :href="getDomainHref('junior')"
               class="grow px-3 md:px-6 py-2 basis-1/2 text-center flex gap-2 transition items-center justify-center bg-transparent"
               :class="{'bg-white/20': checkedDomain === 'junior'}"
               @click="onDomainClick('junior')">
      <span class="material-icons-outlined transition-opacity" :class="{'opacity-10': checkedDomain !== 'junior'}" style="font-size: inherit">done</span>
      {{ $t('purchase.catalog.junior') }}
    </component>
    <component :is="tagName" :href="getDomainHref('www')"
               class="grow px-3 md:px-6 py-2 basis-1/2 text-center flex gap-2 transition items-center justify-center bg-transparent"
               :class="{'bg-white/20': checkedDomain === 'www'}"
               @click="onDomainClick('www')">
      <span class="material-icons-outlined transition-opacity" :class="{'opacity-10': checkedDomain !== 'www'}" style="font-size: inherit">done</span>
      {{ $t('purchase.catalog.www') }}
    </component>
  </div>
</template>